import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const ImageBox = styled.div`
    margin-bottom: 48px;
    width: ${({full}) => (full ? "100%" : "calc(50% - 24px)")};

    &:not(:nth-child(2n)) {
        margin-right: ${({full}) => (full ? "0" : "48px")};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: ${({full}) => (full ? "100%" : "calc(50% - 12px)")};

        &:not(:nth-child(2n)) {
            margin-right: ${({full}) => (full ? "0" : "24px")};
        }
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-bottom: 24px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: ${({full}) => (full ? "100%" : "calc(50% - 6px)")};

        &:not(:nth-child(2n)) {
            margin-right: ${({full}) => (full ? "0" : "12px")};
        }
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_small}) {
        width: 100% !important;
        margin-right: 0 !important;
    }
`

export const Image = styled.div`
    .gatsby-image-wrapper {
        width: 100%;
        border-radius: 10px;
    }
`

export const Description = styled.span`
    font-size: 14px;
    font-weight: 300;
    margin-top: 8px;
    display: block;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 12px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 10px;
        margin-top: 6px;
    }
`
