import React from "react"

import {Container, Description, Image, ImageBox} from "./styles"

export const Images = ({data}) => {
  return (
    <Container>
      {data.image.map((item, i) => (
        <ImageBox key={i} full={data.full}>
          <Image>{item.image}</Image>
          <Description>{item.description}</Description>
        </ImageBox>
      ))}
    </Container>
  )
}
